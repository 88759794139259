<template>
  <div class="app-container">
    <folder-view :title="title" :encryptionStr="encryptionStr" :type="type" :hasBtn="hasBtn" :hasSelect="false"></folder-view>
  </div>
</template>
<script>
import folderView from '@/components/folderView/index.vue'
export default {
  components: { folderView },
  data() {
    return {
      title: '与我分享',
      encryptionStr: '-1',
      type: 'sharedme',
      hasBtn: false
    }
  }
}
</script>
